.posts-navigation {
  color: gray;
  text-transform: uppercase;
  font-size: 0.75em;
  margin-bottom: 2em;
}

// Handwriting

.handwriting {
  font-family: $handwriting-font-family;
  font-weight: normal;
  line-height: initial;
  font-size: 2em;

  @include breakpoint($medium) {
    font-size: 3em;
  }


  a {
    text-decoration: none;
    color: $text-color;

    &:hover {
      color: $accent-color;
    }
  }
}

// Welcome box

.welcome {
  @include breakpoint($medium) {
    font-size: 1.5em;
  }


  a {
    text-decoration: none;
  }

  dl {
    background-color: $code-background-color;
    color: $text-color;
    border-radius: 2em;
    padding: 1.3em;
    margin-bottom: 1.3em;

    &:hover {
      color: $accent-color;
    }
  }

  dt {
    display: inline-block;
    font-family: $handwriting-font-family;
    font-weight: normal;
    font-size: 2em;
    line-height: 1;
    margin-right: 0.5em;

    &:first-child {
      margin-bottom: 0.25em;
    }
  }

  dd {
    display: inline-block;
    font-family: $serif-font-family;
    font-size: 1.7em;
  }
}

// Textrotate

.textrotate:after {
  display: block;
  content: "";
  animation: textrotate 8s linear infinite;
}

@keyframes textrotate {
  0%, 100% {
    content: attr(data-text-1);
  }

  25% {
    content: attr(data-text-2);
  }

  50% {
    content: attr(data-text-3);
  }

  75% {
    content: attr(data-text-4);
  }
}

// home read more rearrangement

.home .page-content {
  display: flex;
  flex-flow: column;

  #all-posts-btn {
    order: 1;
  }

  #twitter-timeline {
    order: 0;
  }
}

// about
.mugshot-about {
  max-width: 400px;
  border-radius: 200px;
}

// make images stand out
.page-content {
  video, img {
    &:not(.no-shadow):not(.entry-image):not(.emoji) {
      border: 2px solid white;
      box-shadow: 0 2px 5px #0006;
    }
  }
}

hr {
  border-style: solid;
}

.rotate180 {
  transform: rotate(180deg);
}

[data-lity] {
  cursor: pointer;
}

[data-lity="500px"] {
  width: 100%;
  max-width: 500px;
}

ol.instructions {
  list-style: none;
  counter-reset: item;
  margin-left: 0.5em;

  > li:first-child::before {
    margin-top: 0;
  }

  > li::before {
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    margin: 1em 0.5em -1em -2.5em;
    content: counter(item);
    counter-increment: item;
    z-index: -1;
    color: white;
    font-weight: bolder;
    text-align: center;
    background-color: $primary-color;
    border-radius: 1em;
  }
}